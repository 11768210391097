<template>
  <v-row class="inventory-bio-panel">
    <!-- inventory profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="dailyMealData.avatar ? '' : 'primary'"
            :class="dailyMealData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img :src="dailyMealData.image"></v-img>
          </v-avatar>
          <span class="mb-2">{{ dailyMealData.item_name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list class="d-flex flex-wrap">
              <v-col cols="12" sm="6">
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Item Name (En):</span>
                  <span class="text--secondary">{{ dailyMealData.item_name_en }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Item Name (Ar):</span>
                  <span class="text--secondary">{{ dailyMealData.item_name_ar }}</span>
                </v-list-item>

                <v-list-item v-if="dailyMealData.chief_name" dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Chef Name:</span>
                  <span class="text--secondary">{{ dailyMealData.chief_name }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Kitchen:</span>
                  <span class="text--secondary">{{ dailyMealData.kitchen_name }}</span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Price:</span>
                  <span class="text--secondary">{{ dailyMealData.price }} JOD</span>
                </v-list-item>
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Total Quantity:</span>
                  <span class="text--secondary">{{ dailyMealData.quantity }} </span>
                </v-list-item>
                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Sold Quantity:</span>
                  <span class="text--secondary">{{ dailyMealData.sold }} </span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Remaining Quantity:</span>
                  <span class="text--secondary">{{ dailyMealData.net_quantity }} </span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Rate:</span>
                  <span class="text--secondary">{{ dailyMealData.rate }} </span>
                </v-list-item>

                <v-list-item dense class="px-0 mb-n2">
                  <span class="font-weight-medium me-2">Status:</span>
                  <span class="text--secondary">{{ dailyMealData.net_quantity ? 'In Stock' : 'Out Stock' }} </span>
                </v-list-item>

                <br />
              </v-col>

              <v-col cols="12" sm="12">
                <h4 v-if="dailyMealData.item_components && dailyMealData.item_components.length > 0">Components</h4>
                <v-list-item
                  class="d-flex flex-wrap"
                  v-if="dailyMealData.item_components && dailyMealData.item_components.length > 0"
                  dense
                >
                  <v-col v-for="(components, index) in dailyMealData.item_components" :key="index" cols="6">
                    <span class="font-weight-medium me-2">component {{ index + 1 }}:</span>
                    <br />
                    <span class="text--primary">Name (En): &nbsp;</span>
                    <span class="text--secondary">{{ components.name_en }}</span>
                    <br />
                    <span class="text--primary">Name (Ar): &nbsp;</span>
                    <span class="text--secondary">{{ components.name_ar }}</span>
                    <br />
                  </v-col>
                </v-list-item>
              </v-col>
            </v-list>
          </v-list>
        </v-card-text>
      </v-card>
      <!-- table -->
      <v-data-table
        v-if="dailyMealData.daily_meals_sub_items.length"
        id="table"
        :headers="tableColumnsForSizes"
        :items="dailyMealData.daily_meals_sub_items"
        class="mt-10"
      >
        <template v-slot:top>
          <v-toolbar rounded short dense color="rgb(104 82 215 / 28%)" flat>
            <v-toolbar-title class="font-weight-medium me-2 text-capitalize">Sizes Table</v-toolbar-title>
          </v-toolbar>
        </template>

        <template #[`item.sub_item_name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ item.sub_item_name_en + ' ' + item.sub_item_name_ar }}
            </div>
          </div>
        </template>

        <template v-slot:item.is_default="{ item }">
          <div>{{ item.is_default ? '✔' : '✘' }}</div>
        </template>

        <template v-slot:item.get_from_others="{ item }">
          <div>{{ item.get_from_others ? '✔' : '✘' }}</div>
        </template>
        
        <template v-slot:item.status="{ item }">
          <div>{{ item.net_quantity ? 'In Stock' : 'Out Stock' }}</div>
        </template>

        <template v-slot:item.image="{ item }">
          <v-img style="margin: auto" contain max-height="40" max-width="110" :src="item.image"></v-img>
        </template>
      </v-data-table>

      <br />
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import useItemsList from '../../inventory-list/useItemsList'
export default {
  props: {
    dailyMealData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
    }
  },
  setup(props, { emit }) {
    const { tableColumnsForSizes } = useItemsList()
    const openPdf = file => {
      window.open(file)
    }
    return {
      tableColumnsForSizes,
      openPdf,
      icons: {
        mdiCheck,
      },
    }
  },
}
</script>
