<template>
  <div id="inventory-view">
    <v-row>
      <v-col cols="12">
        <inventory-bio-panel v-if="dailyMealData" :daily-meal-data="dailyMealData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import inventoryStoreModule from '../inventoryStoreModule.js'
import InventoryBioPanel from './inventory-bio-panel/InventoryBioPanel'
import router from '@/router'

export default {
  components: {
    InventoryBioPanel,
  },
  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'app-inventory'

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME)
    })

    const dailyMealData = ref(null)
    console.log('router', router.currentRoute)
    store
      .dispatch('app-inventory/fetchDailyMealItem', {
        id: router.currentRoute.params.id,
        date: router.currentRoute.query.date,
        type: 'History',
      })
      .then(response => {
        console.log(response);
        dailyMealData.value = response
        console.log('dailyMealData.value',dailyMealData.value)
      })
      .catch(error => {
        if (error.response.status === 404) {
          employeesData.value = {}
        }
      })

    return {
      dailyMealData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
